import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import { Link  } from 'react-router-dom'
import Container from "@material-ui/core/Container";
import Footer from "./Footer";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";

const useStyles = makeStyles((theme) => ({
  toolbar: {
    boxShadow: "1px 1px 6px 0px grey",
    padding: "0px 50px",
  },
  toolbarTitle: {
    flex: 1,
  },
  toolbarSecondary: {
    justifyContent: "space-between",
    overflowX: "auto",
    float: 'right',
    padding: '15px 6px'
  },
  toolbarLink: {
    padding: theme.spacing(1),
    flexShrink: 0,
    fontSize: 20,
    color: "darkblue",
    textDecoration: 'none'
  },
}));

const sections = [
  { title: "Schedule appointment", url: "/appointment" },
  { title: "Find dentist", url: "/find" },
  { title: "My account", url: "/login" },
];
const Header = (Childcomponent) => {
  return function ResponsiveDrawer(props) {
    const classes = useStyles();
    const { children, window } = props;
    const preventDefault = (event) => event.preventDefault();
    const trigger = useScrollTrigger({
      disableHysteresis: true,
      threshold: 0,
      target: window ? window() : undefined,
    });

    return (
      <div>
        <Toolbar className={classes.toolbar}>
          <Container maxWidth="md" >
            <img src="/dental.png" style={{ width: "7%" }} />
            <Toolbar
              component="nav"
              variant="dense"
              className={classes.toolbarSecondary}
            >
              {sections.map((section) => (
                <Link
                  noWrap
                  key={section.title}
                  variant="body2"
                  to={section.url}
                  className={classes.toolbarLink}
                >
                  {section.title}
                </Link>
              ))}
            </Toolbar>
          </Container>
        </Toolbar>
        <Childcomponent {...props} />
        <Footer />
      </div>
    );
  };
};

Header.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default Header;
