import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import {Link,Grid,Typography} from "@material-ui/core";
import Container from "@material-ui/core/Container";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

const useStyles = makeStyles((theme) => ({
  toolbar: {
    boxShadow: "1px 1px 6px 0px grey",
    padding: "0px 50px",
  },
  toolbarTitle: {
    flex: 1,
  },
  toolbarSecondary: {
    justifyContent: "space-between",
    overflowX: "auto",
    float: 'right',
    padding: '15px 6px'
  },
  toolbarLink: {
    padding: theme.spacing(1),
    flexShrink: 0,
    fontSize: 20,
    color: "darkblue",
  },
  listing:{
    listStyleType: 'none',
    display: 'inherit',    width: '100%'
  },
  manageFooter:{
    textAlign:'center',paddingTop:'60px',
    paddingBottom: 40
  },
  footer: {
    boxShadow: "1px 1px 6px 0px grey",
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
}));

const sections = [
  { title: "Back to home", url: "/" },
];
const Header = (Childcomponent) => {
  return function ResponsiveDrawer(props) {
    const classes = useStyles();
    const { children, window } = props;
    const trigger = useScrollTrigger({
      disableHysteresis: true,
      threshold: 0,
      target: window ? window() : undefined,
    });

    return (
      <div>
        <Toolbar className={classes.toolbar}>
          <Container maxWidth="md" >
            <img src="/dental.png" style={{ width: "7%" }} />
            <Toolbar
              component="nav"
              variant="dense"
              className={classes.toolbarSecondary}

            >
              {sections.map((section) => (
                <Link
                  noWrap
                  key={section.title}
                  variant="body2"
                  href={section.url}
                  className={classes.toolbarLink}
                >
                  {section.title}
                </Link>
              ))}
              <ArrowForwardIosIcon />
            </Toolbar>
          </Container>
        </Toolbar>
        <Childcomponent {...props} />
        <div className={classes.toolbar} className={classes.footer}>
        <Container className={classes.cardGrid} maxWidth="md">
          <Grid container spacing={4} justify="space-evenly" className={classes.manageFooter}>
          <Typography variant="h6" color="textPrimary" gutterBottom>
          C1 Smile Dental-branded dental practices are independently owned and operated by licensed dentists.
For more information about the relationship between C1 Smile Dental Management, Inc. and the branded dental practices click here.
            </Typography>
                <ul className='listing'>
                    <li>ACCESSIBILITY  </li>|
                    <li> PRIVACY POLICY  </li>|
                    <li> TERMS OF USE  </li> |
                    <li> SITEMAP  </li> |
                    <li> DENTAL OFFICE LISTING </li>
                </ul>
            <Typography>
            © 2020 C1 Smile Management, Inc. All rights reserved.
            </Typography>
            </Grid>
        </Container>
    </div>
      </div>
    );
  };
};

Header.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default Header;
