import React from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Header from "./Header";
import { Container } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: "url(https://source.unsplash.com/random)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  manageTextFeild :{
    marginRight : 10,
    width: '49%'
  },
  manageHeader :{
    fontSize: '30px',
    fontWeight: 600,
    color: 'lightpink',
    marginLeft: '31px',
    marginTop: '27px',
  }
}));

const Register = () => {
  const classes = useStyles();

  const [state, setState] = React.useState({
    checkedB: true,
  });

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  return (
    <div>
      <Container>
        <Grid item xs={12} sm={8} md={8} elevation={12} square>
            <Typography component="h1" variant="h5" className={classes.manageHeader}>
              Register
            </Typography>
          <div className={classes.paper}>
            <form className={classes.form} noValidate>
              <div style={{ display: "flex" }}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  className={classes.manageTextFeild}
                  label="First Name"
                  name="FirstName"
                  autoFocus
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  className={classes.manageTextFeild}
                  label="Last Name"
                  name="LastName"
                  autoComplete="email"
                />
              </div>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                className={classes.manageTextFeild}
                label="Email Address"
                name="email"
                autoComplete="email"
              />
               <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                className={classes.manageTextFeild}
                label="UserName"
                name="email"
                autoComplete="email"
              />
              <Typography>
              Your username must be unique, at least 8 characters long, and contain only letters & numbers. No special characters.
              </Typography>

              <div style={{ display: "flex" }}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                className={classes.manageTextFeild}
                autoComplete="current-password"
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Confirm Password"
                type="password"
                className={classes.manageTextFeild}
                autoComplete="current-password"
              />
              </div>

              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.checkedB}
                    onChange={handleChange}
                    name="checkedB"
                    color="primary"
                  />
                }
                label="By checking this box and clicking “Create My Account”, I understand and agree to the TERMS & CONDITIONS"
              />

              <Button
                type="submit"

                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Create Account
              </Button>
            </form>
          </div>
        </Grid>
      </Container>
    </div>
  );
};
export default Header(Register);
