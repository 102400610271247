import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import '../index.css'


const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
  },
  toolbar: {
    boxShadow: '1px 1px 6px 0px grey',
    padding: '0px 50px'
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    marginTop: '10px',
    marginBottom: '30px'
  },
  cardHome: {
    marginTop: '10px',
    marginBottom: '30px',
      background: '#032855',
      color: '#fff',
      padding: '40px'
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
  manageLists:{
    listStyleType : 'none',
    paddingLeft : 10
  }
}))

const footers = [
    {
      title: 'Company',
      description: ['Team', 'History', 'Contact us', 'Locations'],
    },
    {
      title: 'Features',
      description: ['Cool stuff', 'Random feature', 'Team feature', 'Developer stuff', 'Another one'],
    },
    {
      title: 'Resources',
      description: ['Resource', 'Resource name', 'Another resource', 'Final resource'],
    },
    {
      title: 'Legal',
      description: ['Privacy policy', 'Terms of use'],
    },
  ];

export default function Dashboard() {
  const classes = useStyles();

  return (
    <div className={classes.toolbar}>
        <Container className={classes.cardGrid} maxWidth="md">
          <Grid container spacing={4} justify="space-evenly">
          {footers.map((footer) => (
                <Grid item xs={6} sm={3} key={footer.title}>
                <Typography variant="h6" color="textPrimary" gutterBottom>
                    {footer.title}
                </Typography>
                <ul className={classes.manageLists}>
                    {footer.description.map((item) => (
                    <li key={item}>
                        <Link href="#" variant="subtitle1" color="textSecondary">
                        {item}
                        </Link>
                    </li>
                    ))}
                </ul>
                </Grid>
            ))}
            </Grid>
        </Container>
    </div>

  );
}
