import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { Link, CardMedia } from "@material-ui/core";
import PersonIcon from '@material-ui/icons/Person';
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "100%",
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  manageContact: {
    paddingTop: 0,
    padding: 0,
  },
  scrollContainer: {
    maxHeight: "100vh",
    overflowY: "scroll",
    overflowX: "hidden",
  },
  cardMedia: {
    paddingTop: "45.25%", // 16:9
    width: "100%",
    height: "60px",
  },
  manageList :{
    borderBottom: "1px solid grey",
    padding : '10px'
  },
  manageHeader : {
    background: 'lightblue',
    margin: 0,
    lineHeight: '16px',
    paddingLeft: '10px',
    display : 'flex'
  }
}));

const Providers = () => {
  const classes = useStyles();
  return (
    <div>
      <Container maxWidth="lg">
        <Grid container>
          <Container  className={classes.scrollContainer} style={{border : '1px solid grey',    width: '1000px',padding: 0}}>
              <div className={classes.manageHeader}>
              <PersonIcon style={{paddingTop: 13,paddingRight: 10,}} />
                <h3>PROVIDERS</h3>
              </div>

            <Grid
              container
              spacing={4}
              className={classes.manageList}
            >
              <Grid item xs={12} sm={12} md={3}>
                <div>
                  <CardMedia
                    className={classes.cardMedia}
                    image="https://source.unsplash.com/random"
                    title="Image title"
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <div>
                  <CardContent className={classes.cardContent}>
                    <div className={classes.manageContact}>
                      <Typography gutterBottom variant="h6" component="h3">
                        Kirston Barrett
                      </Typography>
                      <address>
                        552 Stillwater Ave Bangor, <br />
                        ME 04401 US
                      </address>
                      <Link size="large" color="primary">
                        1 (207) 973-1900
                      </Link>
                    </div>
                  </CardContent>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <div>
                  <CardContent className={classes.cardContent}>
                    <Link size="small" color="primary">
                      <center>
                        <ArrowForwardIosIcon />
                        <Typography>VIEW PROFILE</Typography>
                      </center>
                    </Link>
                  </CardContent>
                </div>
              </Grid>
            </Grid>

            <Grid
              container
              spacing={4}
              className={classes.manageList}
            >
              <Grid item xs={12} sm={12} md={3}>
                <div>
                  <CardMedia
                    className={classes.cardMedia}
                    image="https://source.unsplash.com/random"
                    title="Image title"
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <div>
                  <CardContent className={classes.cardContent}>
                    <div className={classes.manageContact}>
                      <Typography gutterBottom variant="h6" component="h3">
                        Kirston Barrett
                      </Typography>
                      <address>
                        552 Stillwater Ave Bangor, <br />
                        ME 04401 US
                      </address>
                      <Link size="large" color="primary">
                        1 (207) 973-1900
                      </Link>
                    </div>
                  </CardContent>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <div>
                  <CardContent className={classes.cardContent}>
                    <Link size="small" color="primary">
                      <center>
                        <ArrowForwardIosIcon />
                        <Typography>VIEW PROFILE</Typography>
                      </center>
                    </Link>
                  </CardContent>
                </div>
              </Grid>
            </Grid>


          </Container>
        </Grid>
      </Container>
    </div>
  );
};

export default Providers;
