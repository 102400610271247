import React,{useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import {CardMedia,src,TextField} from '@material-ui/core';
import  Header  from  './Header';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import './index.css'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';



const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    marginTop: '10px',
    marginBottom: '30px',
  },
  cardContent:{
    padding: '30px'
  },
  cardHome: {
    marginTop: '10px',
    marginBottom: '30px',
      background: '#032855',
      color: '#fff',
      padding: '40px'
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
  typography:{
    fontSize:"24px",
    paddingBottom:"10px"
  },
  button:{
    marginTop: "30px",
    backgroundColor: "#ff7900",
    border: "none",
    color: "#fff",
    padding: "10px 32px",
    textAlign: "center",
    textDecoration: "none",
    display: "inline-block",
    cursor: "pointer"
  },
  paregraph: {
    fontSize: "20px",
    textAlign: "justify"
  },
  imgCard:{
    paddingTop: '56.25%',
    borderRadius: "50%",
    width: '80%',
    height: '130px'
  },
  grid:{paddingTop: "70px"}
}))


const  Appointment = (props) => {

    const classes = useStyles();
    const [Self, setSelf] = useState('')

    const SubmitForm = ()=>{
      props.history.push('/schedule')
    }

  return (
    <div>
        {Self === '' ? (
            <Container className={classes.cardGrid} maxWidth="md">
            <h1>Who are you scheduling this appointment for?</h1>
            <Grid container spacing={4}>
                <Grid item  xs={12} sm={6} md={4}>
                    <Card className={classes.card} className="card" onClick={e => setSelf('Self')}>
                    <CardContent className={classes.cardContent}  >
                        <center>
                            <PermIdentityIcon style={{fontSize: 95,color: 'pink'}} />
                            <Typography gutterBottom variant="h5" component="h2">
                            MySelf
                            </Typography>
                        </center>
                    </CardContent>
                    </Card>
                </Grid>
                <Grid item  xs={12} sm={6} md={4}>
                    <Card className={classes.card} className="card" onClick={e => setSelf('Other')}>
                        <CardContent className={classes.cardContent}  >
                            <center>
                                <PeopleOutlineIcon style={{fontSize: 95,color: 'pink'}} />
                                <Typography gutterBottom variant="h5" component="h2">
                                    Someone Else
                                </Typography>
                            </center>
                        </CardContent>
                        </Card>
                </Grid>
            </Grid>
        </Container>
        ) : ''}

        {Self === 'Self' || Self === 'Other' ? (
        <Container className={classes.cardGrid} maxWidth="md">
             <Grid container spacing={4}>
                 <Grid item  xs={12} sm={12} md={6}>
                 <Card className={classes.card} >
                 <CardContent className={classes.cardContent}  >
                     <h1>Let's get to know the patient</h1>
                 <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    label="First Name"
                    name="firstname"
                    autoFocus
                    />
                    <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    label="Last Name"
                    autoComplete="lastname"
                    />
                    <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Date of Birth"
                    name="dob"
                    />
                    <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    />
                    <FormControlLabel
                    value="start"
                    control={<Radio color="primary" />}
                    label="Mobile"
                    labelPlacement="end"
                    />
                    <FormControlLabel
                    value="start"
                    control={<Radio color="primary" />}
                    label="Home"
                    labelPlacement="end"
                    />
                    <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Phone"
                    name="dob"
                    />
                    <input type='hidden' value={Self} />
                    <Typography style={{color:"darkblue"}} onClick={e => setSelf('')}>{Self === 'Self' ? 'Scheduling for someone else?' : 'Scheduling for yourself?'}</Typography>
                    <Button size="large" variant="contained" color="primary" className={classes.button} onClick={e => SubmitForm(e)}>Continue</Button>
                    </CardContent>
                </Card>
                </Grid>
            </Grid>
        </Container>
        ) : ''}
    </div>
  );
}

export default Header(Appointment);