import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import CardContent from "@material-ui/core/CardContent";
import Header from "./Header";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    paddingBottom: 80,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  manageAccordion:{
      background: '#fb92a9'
  },
  manageTypo:{
      padding: 16
  }
}));
const sections = [
  { title: "Emergency", icon: "./icon/emergency.svg" },
  { title: "Broken tooth", icon: "./icon/broken_tooth.svg" },
  { title: "Tooth pain", icon: "./icon/tooth_pain.svg" },
  { title: "Dentures", icon: "./icon/dentures.svg" },
  { title: "Clear aligners", icon: "./icon/invisalign.svg" },
  { title: "Check up", icon: "./icon/check_up.svg" },
];
const Schedule = () => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className={classes.root}>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          className={classes.manageAccordion}
        >
          <Typography className={classes.heading}>Reason</Typography>
        </AccordionSummary>
        <Typography variant="h5" component="h2" className={classes.manageTypo}>
            Select a reason for your visit. Don’t see what you need? Call us at (866) 955-1969 for assistance.
            </Typography>
        <AccordionDetails>
          <Grid container spacing={4}>
            {sections &&
              sections.map((item, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Card className={classes.card} className="card">
                    <CardContent className={classes.cardContent} style={{    padding: '60px',    height: '135px'}}>
                      <center>
                        {/* <PermIdentityIcon style={{fontSize: 95,color: 'pink'}} /> */}
                        <img src={item.icon} style={{width: '20%'}} />
                        <Typography gutterBottom variant="h5" component="h2" style={{    paddingTop: '20px'}} >
                          {item.title}
                        </Typography>
                      </center>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
          className={classes.manageAccordion}
        >
          <Typography className={classes.heading}>Location</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Donec placerat, lectus sed mattis semper, neque lectus feugiat
            lectus, varius pulvinar diam eros in elit. Pellentesque convallis
            laoreet laoreet.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
          className={classes.manageAccordion}
        >
          <Typography className={classes.heading}>Date & Time</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Nunc vitae orci ultricies, auctor nunc in, volutpat nisl. Integer
            sit amet egestas eros, vitae egestas augue. Duis vel est augue.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default Header(Schedule);
