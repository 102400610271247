import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import WorkIcon from "@material-ui/icons/Work";
import { Link } from '@material-ui/core';
import StarsIcon from '@material-ui/icons/Stars';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "100%",
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  manageContact: {
    paddingTop: 0,
    padding: 0,
  },
  scrollContainer: {
    maxHeight: "100vh",
    overflowY: "scroll",
    overflowX: "hidden",
  },
  cardMedia: {
    paddingTop: "45.25%", // 16:9
    width: "100%",
    height: "60px",
  },
  manageList: {
    borderBottom: "1px solid grey",
    padding: "10px",
  },
  manageHeader: {
    background: "lightblue",
    margin: 0,
    lineHeight: "16px",
    paddingLeft: "10px",
    display: "flex",
  },
}));

const Faqs = () => {
  const classes = useStyles();
  return (
    <div>
      <Container maxWidth="lg">
        <Grid container>
          <Container
            className={classes.scrollContainer}
            style={{ border: "1px solid grey", width: "1000px", padding: 0 }}
          >
            <div className={classes.manageHeader}>
              <StarsIcon style={{ paddingTop: 13, paddingRight: 10 }} />
              <h3>Links</h3>
            </div>
            <Grid
              container
              spacing={4}
              className={classes.manageList}
            >
              <Grid item xs={12} sm={12} md={9}>
                <Link>Emergency Walk-In Dentist Services | C1 Smile</Link>
                <Typography>
                If you're experiencing pain or need emergency dental services, we will find the
earliest available appointment with an C1 Smile practice at a location near ...
                </Typography>
              </Grid>
            </Grid>

            <Grid
              container
              spacing={4}
              className={classes.manageList}
            >
              <Grid item xs={12} sm={12} md={9}>
                <Link>Emergency Walk-In Dentist Services | C1 Smile</Link>
                <Typography>
                If you're experiencing pain or need emergency dental services, we will find the
earliest available appointment with an C1 Smile practice at a location near ...
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </Grid>
      </Container>
    </div>
  );
};

export default Faqs;
