import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import  Header  from  '../Header';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import '../../index.css'
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Locations from './Locations'
import Providers from './Providers'
import Jobs from './Jobs'
import Faqs from './Faqs'
import Links from './Links'
import { Link } from '@material-ui/core';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "100%",
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },

  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    marginTop: '10px',
    marginBottom: '30px'
  },
  cardHome: {
    marginTop: '10px',
    marginBottom: '30px',
      background: '#032855',
      color: '#fff',
      padding: '40px'
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
}))


const  Menu = () => {
  const classes = useStyles();

  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>

        <Grid container>
          <Grid item  xs={12} sm={12} md={12}>
              <AppBar position="static" color="default">
                  <Container>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="scrollable auto tabs example">
                  <Tab label="LOCATIONS" {...a11yProps(0)} />
                  <Tab label="PROVIDES" {...a11yProps(1)} />
                  <Tab label="JOBS" {...a11yProps(2)} />
                  <Tab label="FAQS" {...a11yProps(3)} />
                  <Tab label="LINKS" {...a11yProps(4)} />
                  <Tab label="EVENT" {...a11yProps(5)} />
                </Tabs>
                  </Container>
              </AppBar>
              <TabPanel value={value} index={0}>
                <Locations />
              </TabPanel>
              <TabPanel value={value} index={1}>
               <Providers />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <Jobs />
              </TabPanel>
              <TabPanel value={value} index={3}>
                <Faqs/>
              </TabPanel>
              <TabPanel value={value} index={4}>
                <Links/>
              </TabPanel>
              <TabPanel value={value} index={5}>
                EVENT
              </TabPanel>
        </Grid>
        </Grid>

    </div>

  );
}

export default Header(Menu);