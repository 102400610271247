import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Link from "@material-ui/core/Link";
import "../../index.css";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import GoogleMapReact from "google-map-react";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import CallIcon from "@material-ui/icons/Call";
import LocationOnIcon from '@material-ui/icons/LocationOn';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "100%",
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  card: {
    marginTop: "10px",
    marginBottom: "30px",
  },
  Typography: {
    paddingBottom: 10,
    fontStyle: "italic",
    color: "grey",
  },
  manageContact: {
    paddingTop: 10,
  },
  scrollContainer: {
    maxHeight: "100vh",
    overflowY: "scroll",
    overflowX: "hidden",
  },
  manageHeader : {
    background: 'lightblue',
    margin: 0,
    lineHeight: '16px',
    paddingLeft: '10px',
    display : 'flex'
  }
}));

const AnyReactComponent = ({ text }) => <div>{text}</div>;
const Locations = () => {
  const classes = useStyles();
  const [state, setstate] = useState({
    center: {
      lat: 59.95,
      lng: 30.33,
    },
    zoom: 11,
  });

  return (
    <div className={classes.root}>
        <Grid container >
          <Grid item xs={12} sm={12} md={6}>
            <div>
              {/* <CardMedia
                className={classes.cardMedia}
                image="https://source.unsplash.com/random"
                title="Image title"
              /> */}
              <div style={{ height: "90vh", width: "100%" }}>
                <GoogleMapReact
                  // bootstrapURLKeys={{ key: 'AIzaSyDigODpFaSYfybk0t0Ek4GSwiutm1dCHdo' }}
                  defaultCenter={state.center}
                  defaultZoom={state.zoom}
                >
                  <AnyReactComponent lat={59.955413} lng={30.337844} />
                </GoogleMapReact>
              </div>
              <CardContent className={classes.cardContent}></CardContent>
              <CardActions></CardActions>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6} className={classes.scrollContainer}>
          <div className={classes.manageHeader}>
              <LocationOnIcon  style={{paddingTop: 13,paddingRight: 10,}} />
                <h3>PROVIDERS</h3>
              </div>
            <Grid
              container
              spacing={4}
              style={{ borderBottom: "2px solid grey" }}
            >
              <Grid item xs={12} sm={12} md={6}>
                <div>
                  <CardContent className={classes.cardContent}>
                    <div className={classes.manageContact}>
                      <Typography gutterBottom variant="h6" component="h3">
                        Name of Hospital
                      </Typography>
                      <Typography className={classes.Typography}>
                        Office hours may vary during the pandemic
                      </Typography>
                      <Typography>
                        <spane style={{ color: "red" }}>Closed - </spane>Opens
                        at 7:00 AM Tuesday
                      </Typography>
                      <address>552 Stillwater Ave Bangor, ME 04401 US</address>
                      <Link size="large" color="primary">
                        1 (207) 973-1900
                      </Link>
                    </div>
                  </CardContent>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <div>
                  <CardContent className={classes.cardContent}>
                    <Link size="small" color="primary">
                      <center>
                        <ArrowForwardIosIcon />
                        <Typography>SCHEDULE APPOINTMENT</Typography>
                      </center>
                    </Link>
                  </CardContent>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={2}>
                <div>
                  <CardContent className={classes.cardContent}>
                    <Link size="small" color="primary">
                      <center>
                        <CallIcon />
                        <Typography>CALL US</Typography>
                      </center>
                    </Link>
                  </CardContent>
                </div>
              </Grid>
              <Divider />
            </Grid>

            <Grid
              container
              spacing={4}
              style={{ borderBottom: "2px solid grey" }}
            >
              <Grid item xs={12} sm={12} md={6}>
                <div>
                  <CardContent className={classes.cardContent}>
                    <div className={classes.manageContact}>
                      <Typography gutterBottom variant="h6" component="h3">
                        Name of Hospital
                      </Typography>
                      <Typography className={classes.Typography}>
                        Office hours may vary during the pandemic
                      </Typography>
                      <Typography>
                        <spane style={{ color: "red" }}>Closed - </spane>Opens
                        at 7:00 AM Tuesday
                      </Typography>
                      <address>552 Stillwater Ave Bangor, ME 04401 US</address>
                      <Link size="large" color="primary">
                        1 (207) 973-1900
                      </Link>
                    </div>
                  </CardContent>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <div>
                  <CardContent className={classes.cardContent}>
                    <Link size="small" color="primary">
                      <center>
                        <ArrowForwardIosIcon />
                        <Typography>SCHEDULE APPOINTMENT</Typography>
                      </center>
                    </Link>
                  </CardContent>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={2}>
                <div>
                  <CardContent className={classes.cardContent}>
                    <Link size="small" color="primary">
                      <center>
                        <CallIcon />
                        <Typography>CALL US</Typography>
                      </center>
                    </Link>
                  </CardContent>
                </div>
              </Grid>
              <Divider />
            </Grid>

            <Grid
              container
              spacing={4}
              style={{ borderBottom: "2px solid grey" }}
            >
              <Grid item xs={12} sm={12} md={6}>
                <div>
                  <CardContent className={classes.cardContent}>
                    <div className={classes.manageContact}>
                      <Typography gutterBottom variant="h6" component="h3">
                        Name of Hospital
                      </Typography>
                      <Typography className={classes.Typography}>
                        Office hours may vary during the pandemic
                      </Typography>
                      <Typography>
                        <spane style={{ color: "red" }}>Closed - </spane>Opens
                        at 7:00 AM Tuesday
                      </Typography>
                      <address>552 Stillwater Ave Bangor, ME 04401 US</address>
                      <Link size="large" color="primary">
                        1 (207) 973-1900
                      </Link>
                    </div>
                  </CardContent>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <div>
                  <CardContent className={classes.cardContent}>
                    <Link size="small" color="primary">
                      <center>
                        <ArrowForwardIosIcon />
                        <Typography>SCHEDULE APPOINTMENT</Typography>
                      </center>
                    </Link>
                  </CardContent>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={2}>
                <div>
                  <CardContent className={classes.cardContent}>
                    <Link size="small" color="primary">
                      <center>
                        <CallIcon />
                        <Typography>CALL US</Typography>
                      </center>
                    </Link>
                  </CardContent>
                </div>
              </Grid>
              <Divider />
            </Grid>

            <Grid
              container
              spacing={4}
              style={{ borderBottom: "2px solid grey" }}
            >
              <Grid item xs={12} sm={12} md={6}>
                <div>
                  <CardContent className={classes.cardContent}>
                    <div className={classes.manageContact}>
                      <Typography gutterBottom variant="h6" component="h3">
                        Name of Hospital
                      </Typography>
                      <Typography className={classes.Typography}>
                        Office hours may vary during the pandemic
                      </Typography>
                      <Typography>
                        <spane style={{ color: "red" }}>Closed - </spane>Opens
                        at 7:00 AM Tuesday
                      </Typography>
                      <address>552 Stillwater Ave Bangor, ME 04401 US</address>
                      <Link size="large" color="primary">
                        1 (207) 973-1900
                      </Link>
                    </div>
                  </CardContent>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <div>
                  <CardContent className={classes.cardContent}>
                    <Link size="small" color="primary">
                      <center>
                        <ArrowForwardIosIcon />
                        <Typography>SCHEDULE APPOINTMENT</Typography>
                      </center>
                    </Link>
                  </CardContent>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={2}>
                <div>
                  <CardContent className={classes.cardContent}>
                    <Link size="small" color="primary">
                      <center>
                        <CallIcon />
                        <Typography>CALL US</Typography>
                      </center>
                    </Link>
                  </CardContent>
                </div>
              </Grid>
              <Divider />
            </Grid>

          </Grid>
        </Grid>
    </div>
  );
};

export default Locations;
