import React from 'react';
import { BrowserRouter,Switch , Route  } from 'react-router-dom'
import Login from './Login'
import Dashboard from './Dashboard'
import Register from './Register'
import Menu from './find/Menu'
import Appointment from './appointment'
import Schedule from './appointment/Schedule'
import Jobs from './find/jobs/index'
// import Authenticate from './Authenticate'
import '../index.css';
class App extends React.Component {
    render(){
        return (
            <BrowserRouter>
                <Switch>
                    <Route path="/"  exact component={Dashboard} />
                    <Route path="/login"  component={Login} />
                    <Route path="/register"  component={Register} />
                    <Route path="/find"  component={Menu} />
                    <Route path="/appointment"  component={Appointment} />
                    <Route path="/schedule"  component={Schedule} />
                    <Route path="/jobs"  component={Jobs} />
                    {/* <Authenticate>
                    </Authenticate> */}
                </Switch>
            </BrowserRouter>

        )
    }
}

export default App