import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import {CardMedia,src} from '@material-ui/core';
import  Header  from  './Header';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import '../index.css'


const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    marginTop: '10px',
    marginBottom: '30px'
  },
  cardHome: {
    marginTop: '10px',
    marginBottom: '30px',
      background: '#032855',
      color: '#fff',
      padding: '40px'
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
  typography:{
    fontSize:"24px",
    paddingBottom:"10px"
  },
  button:{
    marginTop: "30px",
    backgroundColor: "#ff7900",
    border: "none",
    color: "#fff",
    padding: "10px 32px",
    textAlign: "center",
    textDecoration: "none",
    display: "inline-block",
    cursor: "pointer"
  },
  paregraph: {
    fontSize: "20px",
    textAlign: "justify"
  },
  imgCard:{
    paddingTop: '56.25%',
    borderRadius: "50%",
    width: '80%',
    height: '130px'
  },
  grid:{paddingTop: "70px"}
}))


const  Dashboard = () => {
  const classes = useStyles();

  return (
    <div>
        <Container className={classes.cardGrid} maxWidth="md">
        <Card className={classes.card} className={classes.cardHome}>
            <h1>Yes, we're open for all care.</h1>
            <h3>
            Many C1 Smile practices are now open and taking appointments for all types of care.
            </h3>
            <h3><Link >We’re also making your safety our priority with our Smile Wide, Smile Safe Promise.</Link></h3>
        </Card>
          {/* End hero unit */}
          <Grid container spacing={4}>
              <Grid item  xs={12} sm={6} md={4}>
                <Card className={classes.card}>
                  <CardMedia
                    className={classes.cardMedia}
                    image="https://source.unsplash.com/random"
                    title="Image title"
                  />
                  <CardContent className={classes.cardContent}>
                    <Typography gutterBottom variant="h5" component="h2">
                      Heading
                    </Typography>
                    <Typography>
                      This is a media card. You can use this section to describe the content.
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button size="small" color="primary">
                      Learn More
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
              <Grid item  xs={12} sm={6} md={4}>
                <Card className={classes.card}>
                  <CardMedia
                    className={classes.cardMedia}
                    image="https://source.unsplash.com/random"
                    title="Image title"
                  />
                  <CardContent className={classes.cardContent}>
                    <Typography gutterBottom variant="h5" component="h2">
                      Heading
                    </Typography>
                    <Typography>
                      This is a media card. You can use this section to describe the content.
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button size="small" color="primary">
                    Learn More
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
              <Grid item  xs={12} sm={6} md={4}>
                <Card className={classes.card}>
                  <CardMedia
                    className={classes.cardMedia}
                    image="https://source.unsplash.com/random"
                    title="Image title"
                  />
                  <CardContent className={classes.cardContent}>
                    <Typography gutterBottom variant="h5" component="h2">
                      Heading
                    </Typography>
                    <Typography>
                      This is a media card. You can use this section to describe the content.
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button size="small" color="primary">
                    Learn More
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
          </Grid>
        <Grid container spacing={4} className={classes.grid}>
          <Grid item xs={12}  md={6}>
          <Card>
            <CardMedia
              className={classes.cardMedia}
              image="https://source.unsplash.com/random"
              title="Image title" />
          </Card>
          </Grid>
          <Grid item xs={12} md={6}>
              <Typography variant="h3">Introducing Virtual Care</Typography>
              <Typography variant="body1" color="textSecondary" component="p" className={classes.paregraph}>
                Our process has changed, but our care for you hasn’t. For your health and safety, your care can now start with a virtual consultation. If you have a dental issue, just pick up the phone and a trusted member of the C1 Smile team will help bring a doctor to you.
              </Typography>
              <Button size="large" variant="contained" color="primary" className={classes.button}>Learn More</Button>
          </Grid>
        </Grid>
        <Grid container spacing={4} className={classes.grid}>
          <Grid item xs={12} md={6}>
          <center>
            <CardMedia
              className={classes.imgCard}
              image="https://source.unsplash.com/random"
              title="Image title" />
            </center>
          </Grid>
          <Grid item xs={12} md={6}>
              <Typography variant="h3">Our response to COVID-19</Typography>
              <Typography variant="body1" color="textSecondary" component="p" className={classes.paregraph}>
                Many of our offices are now open. During your visit, we’re committed to protecting you, the doctors and our community. We’ve taken steps to put health and safety first while addressing your needs. See the latest on our response.
              </Typography>
              <Button size="large" variant="contained" color="primary" className={classes.button}>Learn More</Button>
          </Grid>
        </Grid>
        <Grid container spacing={4} className={classes.grid}>
          <Grid item xs={12} md={6}>
            <center>

              <CardMedia
                className={classes.cardMedia}
                image="dental.png"
                title="Image title"
                style={{    width: '74%'}}
                />
            </center>

          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h3">We are C1 Smile Strong</Typography>
            <Typography variant="p" color="textSecondary" component="p" className={classes.paregraph}>
              From selfless acts of kindness to shining moments of leadership, C1 Smile team members show strength in times of adversity. Each week, we’re featuring a new face who is impacting their community and bringing hope to the patients they treat.
            </Typography>
            <Button size="large" variant="contained" color="primary" className={classes.button}>Read their stories</Button>
          </Grid>
        </Grid>
        </Container>
    </div>
  );
}

export default Header(Dashboard);