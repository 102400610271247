import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import { CardMedia, src } from "@material-ui/core";
import Header from "../../Header";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Link from "@material-ui/core/Link";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
  },
  cardMedia: {
    paddingTop: "56.25%", // 16:9
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    marginTop: "10px",
    marginBottom: "30px",
  },
  cardHome: {
    marginBottom: "30px",
    background: "#032855",
    color: "#fff",
    padding: "40px",
    borderRadius: 'unset',
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
  typography: {
    fontSize: "24px",
    paddingBottom: "10px",
  },
  button: {
    marginTop: "30px",
    backgroundColor: "#ff7900",
    border: "none",
    color: "#fff",
    padding: "10px 32px",
    textAlign: "center",
    textDecoration: "none",
    display: "inline-block",
    cursor: "pointer",
  },
  paregraph: {
    fontSize: "18px",
    textAlign: "justify",
  },
  imgCard: {
    paddingTop: "56.25%",
    borderRadius: "50%",
    width: "80%",
    height: "130px",
  },
  grid: { paddingTop: "70px" },
  heading:{
        margin: '17px 0',
        color: 'dodgerblue',
  }
}));

const JobsApply = () => {
  const classes = useStyles();

  return (
    <div>
      <Card className={classes.card} className={classes.cardHome}>
        <center>
          <h1>Dentist - DDS / DMD</h1>
          <h3>in Thomaston, ME</h3>
          <p>17 Thomaston Commons Way Thomaston, ME 04861</p>
          <Button size="large" color="secondary" variant="contained">
            Apply Now
          </Button>
        </center>
      </Card>
      <Container className={classes.cardGrid} maxWidth="md">
        <center>
          <h1>Job Overview</h1>
        </center>
        <Grid container spacing={4} className={classes.grid}>
          <Grid item xs={12} md={8}>
            <Typography variant="h4" className={classes.heading} >Introducing Virtual Care</Typography>
            <Typography
              variant="body1"
              color="textSecondary"
              component="p"
              className={classes.paregraph}
            >
              Our process has changed, but our care for you hasn’t. For your
              health and safety, your care can now start with a virtual
              consultation. If you have a dental issue, just pick up the phone
              and a trusted member of the C1 Smile team will help bring a doctor
              to you.
            </Typography>

            <Typography variant="h4" className={classes.heading}>Say yes to</Typography>
            <Typography
              variant="body1"
              color="textSecondary"
              component="p"
              className={classes.paregraph}
            >
              <ul>
                <li>
                  High needs patients - restorative, surgery, dentures and
                  implants
                </li>
                <li>Cash and PPO payor mix</li>
                <li>
                  State-of-art-equipment including new iTero scanners, cone
                  beam, and 3D digital printing
                </li>
                <li>
                  Well-trained team focused on delivering a superior patient
                  experience
                </li>
                <li>Working & learning with Specialists on site</li>
              </ul>
            </Typography>

            <Typography variant="h4" className={classes.heading}>Benifits:</Typography>
            <Typography
              variant="body1"
              color="textSecondary"
              component="p"
              className={classes.paregraph}
            >
              <ul>
                <li>
                  1st year earning potential 2x traditional private practice
                  model*
                </li>
                <li>Up to $200K student loan reimbursement**</li>
                <li>
                  Up $50K sign on bonus opportunities throughout the network**
                </li>
                <li>
                  Thousands of hours of free Continuing Education (live and
                  online)
                </li>
                <li>
                  Instant peer network of 1200+ dentists for clinical &
                  developmental support
                </li>
                <li>A fast track to practice ownership</li>
              </ul>
            </Typography>

            <Typography variant="h4" className={classes.heading}>Benifits:</Typography>
            <Typography
              variant="body1"
              color="textSecondary"
              component="p"
              className={classes.paregraph}
            >
              <ul>
                <li>
                  Assess treatment planning options and discuss with patient.
                </li>
                <li>
                  Assist in the recruitment, training, and management of staff.
                </li>
                <li>Carry out agreed clinical treatments.</li>
                <li>
                  Complete comprehensive full mouth exams and diagnose dental
                  conditions.
                </li>
                <li>
                  Keep abreast of new developments in dentistry through
                  structured continuing professional developments.
                </li>
                <li>
                  Maintain affiliation with professional groups, dental
                  associations and dental societies to represent Aspen
                  Dental-branded practices in the local community.
                </li>
                <li>
                  This is a Lead Dentist role. Apply today to speak with a
                  recruiter and learn more!
                </li>
              </ul>
            </Typography>

            <Typography variant="h4" className={classes.heading}>Minumum Requirements:</Typography>
            <Typography
              variant="body1"
              color="textSecondary"
              component="p"
              className={classes.paregraph}
            >
              <ul>
                <li>Must be a DDS or DMD from an accredited school.</li>
              </ul>
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card>
              <CardMedia
                className={classes.cardMedia}
                image="https://source.unsplash.com/random"
                title="Image title"
              />
            </Card>
            <br />
            <Card>
              <CardMedia
                className={classes.cardMedia}
                image="https://source.unsplash.com/random"
                title="Image title"
              />
            </Card>
            <br />
            <Card>
              <CardMedia
                className={classes.cardMedia}
                image="https://source.unsplash.com/random"
                title="Image title"
              />
            </Card>
          </Grid>
        </Grid>

        <div>
          <center>
            <h1>More About this office</h1>
            <Grid container spacing={4} className={classes.grid}>
              <Grid item xs={12} md={6}>
                  <h2>Address:</h2>
                  <address>
                  17 Thomaston Commons Way <br/>
                  Thomaston, ME 04861
                  </address>
              </Grid>
              <Grid item xs={12} md={6}>
                  <h2>Contact recuiter:</h2>
                  <address>
                  (866) 529-2361
                  </address>
              </Grid>
            </Grid>
          </center>
        </div>
      </Container>
    </div>
  );
};

export default Header(JobsApply);
